<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          label-width="70px"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model.trim="formInline.sendState" filterable placeholder="请选择">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="已发送" value="0"></el-option>
                  <el-option label="未发送" value="1"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.config"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                @click="$router.push('allInformationConfAdd')"
                :loading="loading"
                v-if="$route.meta.authority.button.config"
                >配置消息</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper paddingB10 bgFFF">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="80"
            v-if="$route.meta.authority.button.view"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0">
                  操作
                  <i class="el-icon-arrow-down" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="$route.meta.authority.button.view"
                    >查看</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      tableCols: [
        {
          prop: "msgTitle",
          label: "标题",
          width: "",
        },
        {
          prop: "editorName",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "sendTime",
          label: this.$t("list.Sending_time"),
          width: "",
        },
        {
          prop: "sendState",
          label: this.$t("list.state"),
          width: "",
          formatter: function (row) {
            let obj = {
              0: "已发送",
              1: "未发送",
            };
            return obj[row.sendState];
          },
        },
      ],
      loading: false,
      tableData: [],
      formInline: {
        sendState: "",
        editorName: "",
      },
    };
  },
  methods: {
    handleCommand(cmd, data) {
      if (cmd === "a") {
        this.view(data);
      }
    },
    view(data) {
      this.$router.push({
        path: "allinformationConfDetails",
        query: { ...data },
      });
    },
    success() {
      this.searchData();
    },
    openMuli() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.open();
    },
    searchTimeChange() {},
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    searchData() {
      this.$axios
        .get("/acb/2.0/sysmgMsgConf/list", {
          data: {
            ...this.formInline,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.tableData = [];
          this.tableData = res.value.list;
          this.total = res.value.total * 1 || 0;
        });
    },
  },
  mounted() {
    this.searchData();
  },
  activated() {
    this.searchData();
  },
};
</script>
<style lang="stylus" scoped>
.allInformationConf {
  .breadcrumb {
    height: 35px;
  }
  .content {
    overflow: hidden;
    .pagerWrapper {
      text-align: right;
      margin-top: 28px;
      font-size: 12px;
    }
  }
}
</style>
